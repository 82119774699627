import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Henna | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/henna/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const MedycynaEstetyczna = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Henna brwi</h1>

        <p className="lead">
          Zabieg henny to jeden z&nbsp;popularnych zabiegów kosmetycznych, który staje się coraz bardziej popularny w&nbsp;salonach kosmetycznych na całym świecie. Polega on na nałożeniu na włosy specjalnej mieszanki z&nbsp;henny, która nadaje im piękny, zdrowy wygląd oraz odżywia i&nbsp;regeneruje cebulki włosów.
        </p>

        <p>Henna to naturalny składnik pochodzący z&nbsp;rośliny o&nbsp;tej samej nazwie, który od wieków stosowany jest w&nbsp;różnych kulturach do farbowania włosów i&nbsp;tatuaży. Ma ona właściwości odżywcze i&nbsp;nawilżające, dzięki czemu idealnie nadaje się do pielęgnacji włosów suchych i&nbsp;zniszczonych.</p>

        <p>Przed przystąpieniem do zabiegu henny, kosmetyczka dokładnie ocenia stan włosów klientki, aby móc dobrać odpowiednią mieszankę henny oraz innych składników, takich jak np. oleje, miód czy wyciągi z&nbsp;roślin. Następnie brwi są dokładnie umyte i&nbsp;osuszone, a&nbsp;następnie nałożona jest na nie mieszanka henny, która musi na nich pozostać przez określony czas, aby mogła działać.</p>

        <p>Po upływie wymaganego czasu, mieszanka zostaje zmyta. Rezultatem zabiegu jest piękny, zdrowy kolor brwi oraz ich odżywiona i&nbsp;nawilżona struktura.</p>

        <p>Zabieg henny jest całkowicie bezpieczny dla włosów, ponieważ jest w&nbsp;pełni naturalny i&nbsp;nie zawiera szkodliwych chemikaliów. Może być stosowany zarówno przez kobiety, jak i&nbsp;mężczyzn, a&nbsp;także przez osoby o&nbsp;różnym kolorze włosów i&nbsp;typie skóry.</p>

        <p>Zabieg henny w salonie kosmetycznym jest doskonałą alternatywą dla osób, które chcą odżywić i&nbsp;nawilżyć swoje włosy oraz nadać im piękny kolor bez użycia szkodliwych chemikaliów.</p>

        <h2>Cena: od 40 zł.</h2>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default MedycynaEstetyczna;
